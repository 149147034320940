<template>
  <div>
    <el-dialog
      title="报名详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="1000px"
    >
      <div class="RegistrationDetails">
        <div class="CollectivePurchaseTable">
          <table border="1">
            <tr>
              <td>集采项目</td>
              <td colspan="3">{{ detailData.title }}</td>
            </tr>
            <tr>
              <td>订单编号</td>
              <td>{{ detailData.applyCode }}</td>
              <td>报名时间</td>
              <td>{{ detailData.applyTime }}</td>
            </tr>
            <tr>
              <td>预付费</td>
              <td>{{ detailData.prepayment }}元</td>
              <td>保证金审核通过时间</td>
              <td>{{ detailData.successfulTime }}</td>
            </tr>
          </table>
        </div>
        <!-- <div class="detailBtn">
          <el-button type="primary">项目详情</el-button>
        </div> -->
      </div>
      <div class="tableDetail">
        <table-page :data="tableData" :columns="columns" :border="true">
          <template slot="guidePrice" slot-scope="scope">
            {{ scope.row.guidePrice + "元/" + scope.row.unit }}
          </template>
          <template slot="quantity" slot-scope="scope">
            {{ scope.row.quantity + scope.row.unit }}
          </template>
        </table-page>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TablePage from "@/components/table/CallForBids-page";
import { RegisterToView } from "../../tableColumn";
import { selectApplyDetail } from "@/api/CollectivePurchase";
export default {
  name: "NowDetails",

  data() {
    return {
      dialogVisible: true,
      columns: RegisterToView,
      tableData: [],
      detailData: [],
    };
  },
  components: {
    TablePage,
  },
  mounted() {},

  methods: {
    back() {
      this.$emit("closeDialog", false);
    },
    handleClose() {
      this.$emit("closeDialog", false);
    },
    async intShow(applyId) {
      let params = applyId;
      let res = await selectApplyDetail(params);
      this.tableData = res.data.newAgriculturalVoList;
      this.detailData = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.RegistrationDetails {
  width: 100%;
  height: 100px;
  display: flex;
  .CollectivePurchaseTable {
    width: 100%;
    height: 100%;
    border-top: solid 2px #1c9c47;
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      text-align: center;
    }
  }
  /* .detailBtn {
    padding-top: 20px;
    width: 20%;
    height: 50px;
    display: flex;
    justify-content: center;
  } */
}
</style>
