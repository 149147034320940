// 农资集采
import service from '../request'
const baseUrl = window.globalUrl.HOME_API

//农资规格管理-一级种类字典查询
export const nodeList = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/dict/type/${params}`,
    params: params
  })
}

// 农资规格管理-种类树状图查询
export const treeList = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectAgriculturalTree`,
    params: params
  })
}

// 农资规格管理-二级种类新增接口
export const addNode = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/procurement/saveSecAgricultural`,
    data: params
  })
}

// 农资规格管理-添加新农资接口

export const AgriculturalCapital = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/procurement/saveNewAgricultural`,
    data: params
  })
}

// 农资规格管理-列表查询接口

export const dataList = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectAgriculturalList`,
    params: params
  })
}

// 农资规格管理-编辑接口

export const updateAgricultural = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/procurement/updateAgricultural`,
    data: params
  })
}

//农资规格管理-删除接口

export const deleteAgriculturalManagement = (agriculturalId) => {
  return service({
    method: 'delete',
    url: `${baseUrl}/trade-website/procurement/deleteAgriculturalManagement/` + agriculturalId,
  })
}

// 农资规格管理-导入总农资表接口

export const saveAgriculturalManagement = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/procurement/saveAgriculturalManagement`,
    data: params
  })
}

// 农资规格管理-总农资表树状图查询接口

export const selectTotalTree = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectTotalTree`,
    params: params
  })
}

//农资规格管理-总农资表列表查询接口

export const selectTotalAgricultural = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectTotalAgricultural`,
    params: params
  })
}


// 发布集采统计-新增集采接口

export const saveProcurement = (params) => {
  return service({
    method: 'Post',
    url: `${baseUrl}/trade-website/procurement/saveProcurement`,
    data: params
  })
}
// 用户报名记录-更新状态接口
export const updateCancel = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/procurement/updateCancel`,
    data: params
  })
}
// 集采信息管理-列表查询

export const selectUserApply = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectUserApply`,
    params: params
  })
}

// 用户报名记录-列表查询接口

export const selectApplyRecordList = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectApplyRecordList`,
    params: params
  })
}
// 用户报名记录 - 查看详情接口
export const selectApplyDetail = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectApplyDetail/${params}`,
  })
}


// 集采农资规格公告公示

export const selectProcurementNotice = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectProcurementNotice`,
    params: params
  })
}

//发布集采统计-集采公告详情接口

export const selectDetailProcurement = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectDetailProcurement/${params}`,
  })
}

//发布集采统计-集采公告详情接口

export const selectDetailCopy = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectDetailCopy/${params}`,
  })
}

// 集采统计-报名接口

export const applyProcurement = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/procurement/applyProcurement`,
    data: params
  })
}
// 用户报名记录-支付信息接口
export const selectPaymentDetail = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectPaymentDetail`,
    params: params
  })
}
// 招标公告-发布招标公告接口
export const saveInciteTenders = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/inviteTenders/saveInciteTenders`,
    data: params
  })
}
// 中标公告-发布中标公告接口
export const saveSuccessfulBidder = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/inviteTenders/saveSuccessfulBidder`,
    data: params
  })
}
// 招标公告-详情接口
export const selectDetailTenders = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/inviteTenders/selectDetailTenders/${params}`,
  })
}
// 招标公告-招标公告公示列表
export const selectTenderList = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/inviteTenders/selectTenderList`,
    params: params
  })
}

// 中标公告-中标公告公示列表
export const selectBidderList = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/inviteTenders/selectBidderList`,
    params: params
  })
}


// 集采信息管理-报名记录详情接口
export const selectApplyForm = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectApplyForm`,
    params: params
  })
}

//集采信息管理-数量统计列表查询接口

export const selectCounting = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectCounting`,
    params: params
  })
}

// 集采信息管理-查看报名详情接口·

export const selectDetailApplyForm = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/selectDetailApplyForm`,
    params: params
  })
}

//集采信息管理-导出明细

export const exportApplyReport = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/procurement/exportApplyReport`,
    params: params,
    responseType: "blob",
  })
}


//查看集采招标中标类型
export const selectNoticeType = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/inviteTenders/selectNoticeType`,
    params: params,
  })
}


// 中标公告-详情接口
export const selectDetailSuccessful = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/inviteTenders/selectDetailSuccessful/${params}`,
  })
}
// 用户上传凭证

export const uploadBond = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/tdProcurementBond/uploadBond`,
    data: params
  })
}
// 运营公司查看凭证详情

export const selectBondDetail = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/tdProcurementBond/selectBondDetail`,
    params: params
  })
}

// 运营公司审核保证金

export const procurementAuditBond = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/tdProcurementBond/procurementAuditBond`,
    data: params
  })
}
// 获取品种价格树
export function getTreeList(params) {
  return service({
    method: 'get',
    url: ` ${baseUrl}/trade-website/category/tree`,
    params: params
  })
}

// 审核用户
export function deleteTreeById(id) {
  return service({
    method: 'delete',
    url: `${baseUrl}/trade-website/category/${id} `,
  })
}
// 到游仙区的行政区划
export const getRegionToCity = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/area/areaTree`,
    params: params,
  })
}
//目录树过滤
export function filterTree(categoryCode, type) {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/category/${categoryCode}/${type}`,
  })
}
// 目录树新增
export function addTreeObj(params) {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/category/save`,
    data: params
  })
}

